.App {
  text-align: center;
}

.App-header {
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #303030;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.table{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

div.tooltip {
    position: absolute;
    text-align: center;
    /* width: 60px;
    height: 28px; */
    padding: 5px;
    font: 18px sans-serif;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 8px;
    pointer-events: none;
}


body.light-mode {
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s ease;
}
body.dark-mode {
  background-color: #1a1919;
  color: #999;
}

cfont {font-family: 'Caveat', cursive;}